import { media } from '../../../common/utils/utils';
import officeStyles from './office/lang-switcher-styles';
import mainStyles from './main/lang-switcher-styles';
import uikit from '../../uikit/styles';

const stylesheets = (width, height, variable = {}) => ({
    officeStyles,
    mainStyles,
    menuLangsWrapperMobile: {
        position: 'relative',
        'z-index': '99'
    },
    menuLangMargin: { 'margin-left': '0' },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherInfo: { padding: '0 0 0 20px' },
    menuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    menuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    langSwitcher: media(width, height, 'w', {
        992: { width: '74px' },
        0: { width: '44px' }
    }),
    menuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            'border-bottom': `1px solid ${variable.darkSuperTransparentColor}`,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    menuLangSwitcherFlag: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        fill: 'inherit'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    modalLangListDesktop: {
        'overflow': 'hidden',
        'border-radius': '14px'
    },
    modalLangListArrow: {
        position: 'absolute',
        top: '-12px',
        height: '14px',
        right: '50%',
        width: '33px',
        transform: 'translateX(50%)',
        'z-index': '0',
        fill: variable.whiteColor
    },
    modalLangListArrowLeft: { right: '24px' },
    modalLangListWrap: media(width, height, 'w', {
        1301: {
            position: 'absolute',
            top: '100%',
            right: '0%',
            'background': variable.whiteColor,
            'border-radius': '20px',
            'margin-top': '8px',
            'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)'
        },
        0: {
            position: 'absolute',
            top: '100%',
            right: '0%',
            'background': variable.whiteColor,
            'border-radius': '0 20px 20px 0',
            'margin-top': '8px',
            'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)'
        }
    }),
    modalLangListWrapLeft: {
        right: '0',
        transform: 'none'
    },
    modalLangListItemFirst: {
        'border-top': 'none',
        'padding': '24px 24px 8px 24px'
    },
    modalLangListItemLast: { 'padding': '8px 24px 24px 24px' },
    modalLangListItemHover: { 'color': 'rgba(46, 109, 142, 1)' },
    menuLangSwitcherListFlag: {
        'margin-right': '8px',
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '700',
        'font-size': '12px',
        'line-height': '15px',
        'letter-spacing': '0.06em',
        'min-width': '17px',
        'max-width': '17px',
        color: 'inherit',
        'text-transform': 'uppercase',
        display: 'flex',
        'justify-content': 'center',
        // background: variable.greyExtraLightColor,
        // width: '20px',
        // 'min-width': '20px',
        // height: '20px',
        // 'border-radius': '20px'
    },
    menuLangSwitcherInfoDesktop: {
        ...uikit.small,
        'white-space': 'pre',
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '500',
        'font-size': '18px',
        'line-height': '22px',
        color: 'inherit'
    },
    modalLangListItemDesktop: {
        display: 'flex',
        'align-items': 'center',
        padding: '8px 24px',
        'cursor': 'pointer',
        fill: variable.blueTotalGray,
        color: '#A4B3CA'
    },
    mobileMenuLangSwitcher: media(width, height, 'w', {
        0: {
            'min-height': '65px',
            width: '100%',
            'border-top-style': 'solid',
            'border-top-width': '1px',
            'border-top-color': variable.darkSuperTransparentColor,
            'border-bottom-style': 'solid',
            'border-bottom-width': '1px',
            'border-bottom-color': variable.darkSuperTransparentColor,
            padding: '16px 23px',
            color: variable.darkColor,
            background: variable.whiteColor,
            display: 'flex',
            'flex-direction': 'row',
            'justify-content': 'flex-start',
            'align-items': 'center'
        }
    }),
    mobileMenuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        width: '24px',
        height: '24px',
        'border-radius': '50%',
        background: variable.greyColor,
        overflow: 'hidden'
    },
    mobileMenuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    mobileMenuLangSwitcherInfo: {
        // padding: '0 0 0 20px',
        'text-align': 'left'
    },
    mobileMenuLangSwitcherTitle: {
        ...uikit.tiny,
        'line-height': '1.16',
        color: variable.greySuperDarkColor,
        margin: '0 0 2px'
    },
    mobileMenuLangSwitcherName: {
        ...uikit.small,
        'line-height': '1.14'
    },
    modalContent: media(width, height, 'w', {
        0: {
            width: '100%',
            height: '100%',
            display: 'flex',
            'justify-content': 'center',
            background: variable.blackTransparentColor,
            'flex-direction': 'row',
            'align-items': 'flex-end'
        }
    }),
    modalBody: media(width, height, 'w', {
        425: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        },
        0: {
            width: '100%',
            background: variable.whiteColor,
            padding: '16px 12px 8px',
            'border-radius': '20px 20px 0px 0px',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center'
        }
    }),
    modalTopDecor: {
        width: '32px',
        height: '4px',
        background: variable.greyExtraLightColor,
        'border-radius': '100px',
        margin: '0 0 20px'
    },
    modalBottomDecor: {
        width: '134px',
        height: '4px',
        margin: '21px 0 0'
    },
    modalTitle: {
        'font-weight': 500,
        ...uikit.subtitle
    },
    modalLangListWrapper: {
        width: '100%',
        overflow: 'hidden'
    },
    modalLangList: { margin: '-3px 0 -1px' },
    modalLangListItem: {
        'border-bottom-style': 'solid',
        'border-bottom-width': '1px',
        'border-bottom-color': variable.darkExtraTransparentColor,
        padding: '16px 0',
        display: 'flex',
        'flex-direction': 'row',
        'align-items': 'center'
    },
    menuLangsWrapper: media(width, height, 'w', {
        768: {
            position: 'relative',
            'z-index': '99'
        },
        0: { display: 'none' }
    }),
    menuLangsBackdrop: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'transparent',
        'z-index': '-2'

    },
    menuLangSwitcherDesktop: media(width, height, 'w', {
        1320: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-right': '2px solid #FAFF1B',
            padding: '0 24px',
            'border-radius': '30px 0px 0px 30px',
            background: 'rgba(255, 255, 255, 0.2)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'white',
            fill: 'white'
        },
        0: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-left': '2px solid #FAFF1B',
            padding: '0 24px',
            'border-radius': '0 30px 30px 0',
            background: 'rgba(255, 255, 255, 0.2)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'white',
            fill: 'white'
        }
    }),
    menuLangSwitcherDesktopDark: media(width, height, 'w', {
        1320: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-right': '2px solid #FAFF1B',
            padding: '0 24px',
            'border-radius': '30px 0px 0px 30px',
            background: 'rgba(255, 255, 255, 0.2)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'black',
            fill: 'black'
        },
        0: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-left': '2px solid #FAFF1B',
            padding: '0 24px',
            'border-radius': '0 30px 30px 0',
            background: 'rgba(255, 255, 255, 0.2)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'black',
            fill: 'black'
        }
    }),
    menuLangSwitcherDesktopHover: media(width, height, 'w', {
        1320: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-right': '2px solid rgba(0, 0, 0, 1)',
            padding: '0 24px',
            'border-radius': '30px 0px 0px 30px',
            background: 'rgba(250, 255, 27, 1)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'rgba(0, 0, 0, 1)',
            fill: 'rgba(0, 0, 0, 1)'
        },
        0: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            'border-left': '2px solid white',
            padding: '0 24px',
            'border-radius': '0 30px 30px 0',
            background: 'rgba(250, 255, 27, 1)',
            'box-shadow': '0px 4px 8px rgba(0, 0, 0, 0.1)',
            'backdrop-filter': 'blur(3px)',
            color: 'rgba(0, 0, 0, 1)',
            fill: 'rgba(0, 0, 0, 1)'
        }
    }),
    menuLangSwitcherLangFlag: {
        'flex-grow': '0',
        'flex-shrink': '0',
        background: 'transparent',
        display: 'flex',
        'justify-content': 'center',
        'align-items': 'center',
        overflow: 'hidden'
    },
    menuLangSwitcherLangFlagImg: {
        width: '24px',
        height: '24px'
    },
    menuLangSwitcherLangFlagImgDesktop: {
        width: '20px',
        'min-width': '20px',
        height: '20px',
        'border-radius': '20px'
    },
    menuLangSwitcherNameDesktop: {
        'text-transform': 'uppercase',
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '600',
        'font-size': '14px',
        'line-height': '18px',
        'letter-spacing': '0.06em',
        color: 'inherit',
        'margin-left': '4px'
    },
    modalTitle: {
        color: variable.darkColor,
        ...uikit.title,
        'text-align': 'center',
        'font-weight': '500',
        'max-width': '376px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        'margin-bottom': '32px'
    },
    FormWeb: media(width, height, 'w', {
        768: { width: '100%' },
        0: {
            width: '100%',
            display: 'flex',
            'flex-direction': 'column',
            'flex-grow': 1
        }
    }),
    formGroupWrapper: {
        display: 'flex',
        'flex-direction': 'column',
        'flex-wrap': 'nowrap',
        'justify-content': 'flex-start',
        'align-items': 'stretch',
        'margin-bottom': '16px',
        'max-width': '100%',
        width: '100%',
        flex: 'none'
    },
    modalClose: {
        position: 'absolute',
        top: '24px',
        right: '30px',
        'cursor': 'pointer'
    },
    modalCloseSvg: {
        fill: variable.greyExtraDarkColor,
        width: '12px',
        height: '12px'
    },
    modalWrapper: {
        'min-width': '576px',
        'max-width': '100%',
        background: variable.whiteColor,
        'border-radius': '20px',
        'margin-left': 'auto',
        'margin-right': 'auto',
        position: 'relative',
        display: 'flex',
        'flex-direction': 'column',
        padding: '32px'
    },
    dropDownBtnsWrapper: {
        display: 'flex',
        'align-items': 'center',
        'justify-content': 'space-between',
        'margin-top': '12px'
    },
    dropDownText: { 'min-width': 'max-content' },
    dropDownWrapper: {
        filter: `drop-shadow(${variable.blackTransparentColor} 0px 10px 100px)`,
        display: 'flex',
        'flex-direction': 'column',
        'justify-content': 'center',
        'align-items': 'center',
        position: 'absolute',
        'z-index': '100',
        top: 'calc(100% + 6px)',
        left: '50%',
        transform: 'translateX(-50%)',

        'font-style': 'normal',
        'font-weight': '500',
        ...uikit.small,
        'line-height': '1.14',
        color: variable.darkColor,
        'text-align': 'center'
    },
    dropDown: {
        'background': variable.whiteColor,
        'border-radius': '14px',
        padding: '16px 30px',
        position: 'relative',
        top: '-5px'
    },
    triangleDropdown: {
        width: 0,
        height: 0,
        'border-left': '16.5px solid transparent',
        'border-right': '16.5px solid transparent',

        'border-bottom': `15px solid ${variable.whiteColor}`
    },
    wrapper: {
        padding: '12px',
        height: '48px',

        display: 'flex',
        'align-items': 'center',
        'justify-content': 'center',
        'cursor': 'pointer',
        'border-left': 'none',

        'font-style': 'normal',
        'font-weight': 'normal',
        ...uikit.small,
        'line-height': '1.14'
    },

    // #region LEADERS PAGE LANGSWITCHER
    leadersmenuLangSwitcherNameDesktop: {
        'text-transform': 'capitalize',
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '600',
        'font-size': '14px',
        'line-height': '18px',
        'letter-spacing': '0.06em',
        color: 'inherit',
        'margin-left': '4px'
    },
    leadersmenuLangSwitcherDesktopDark: media(width, height, 'w', {
        1320: {
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            background: 'rgba(255, 255, 255, 0.2)',
            color: 'black',
            fill: 'black'
        },
        0: {
            height: '38px',
            display: 'flex',
            'align-items': 'center',
            'justify-content': 'center',
            'cursor': 'pointer',
            background: 'rgba(255, 255, 255, 0.2)',
            color: 'black',
            fill: 'black'
        }
    }),
    reactCountryFlag: {
        width: '24px',
        height: '24px',
        borderRadius: '50%',
        objectFit: 'cover'
    },
    leadersmenuLangsWrapper: {
        position: 'relative',
        'z-index': '99'
    },
    leadersmodalLangListWrap: media(width, height, 'w', {
        1320: {
            position: 'absolute',
            top: '100%',
            right: '0%',
            'background': variable.whiteColor,
            'border-radius': '20px',
            'margin-top': '8px',
            'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)'
        },
        0: {
            position: 'absolute',
            top: '70%',
            right: '0%',
            'background': variable.whiteColor,
            'border-radius': '20px',
            'margin-top': '8px',
            'box-shadow': '0px 8px 16px rgba(0, 0, 0, 0.08)'
        }
    }),
    leadersmodalLangListItemDesktop: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            padding: '8px 24px',
            'cursor': 'pointer',
            fill: variable.blueTotalGray,
            color: '#A4B3CA'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            padding: '4px 12px',
            'cursor': 'pointer',
            fill: variable.blueTotalGray,
            color: '#A4B3CA'
        }
    }),
    leadersmodalLangListItemDesktop: media(width, height, 'w', {
        768: {
            display: 'flex',
            'align-items': 'center',
            padding: '8px 24px',
            'cursor': 'pointer',
            fill: variable.blueTotalGray,
            color: '#A4B3CA'
        },
        0: {
            display: 'flex',
            'align-items': 'center',
            padding: '4px 12px',
            'cursor': 'pointer',
            fill: variable.blueTotalGray,
            color: '#A4B3CA'
        }
    }),
    leadersmenuLangSwitcherInfoDesktop: {
        ...uikit.small,
        'white-space': 'pre',
        'font-family': 'Gilroy, sans-serif',
        'font-weight': '500',
        color: 'inherit'
    },
    // #endregion LEADERS PAGE LANGSWITCHER
});
export default stylesheets;